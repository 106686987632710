@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.base-note-input__footer {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.base-note-input__private-input {
  display: inline-block;
}

.base-note-input__private-input-hint {
  display: block;
}

.base-note-input__attachment-buttons {
  margin-top: 1.5rem;
}

.base-note-input__attachment-wrapper {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
}

.base-note-input__attachment-input-wrapper {
  margin-top: 1.5rem;
}

.base-note-input__attachment-progress-wrapper {
  display: flex;
  align-items: center;
}

.base-note-input__attachment-progress {
  flex-grow: 1;
}

.base-note-input__attachment-name {
  font-weight: 600;
}

.base-note-input__remove-attachment {
  margin-left: 1rem;
}
