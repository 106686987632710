@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.feed-post-input__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.feed-post-input__button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0 0 10px;
}

.feed-post-input__avatar {
  margin-top: 4px;
  margin-right: 10px;
}

.feed-post-input__post-error {
  flex-basis: 100%;
  color: $boston-university-red;
  font-weight: 500;
  font-size: 12px;
}

.feed-post-input__link-error {
  color: $dark-goldenrod;
  font-weight: 500;
  font-size: 12px;

  & ::v-deep .icon {
    top: auto;
  }
}

.feed-post-input__video-preview {
  position: relative;
}

.feed-post-input__video-preview__close-button {
  z-index: 10;
}

.team-post-input__video-preview__progress {
  display: flex;
  align-items: center;
}

.team-post-input__video-preview__progress-bar {
  flex-grow: 1;
}

.feed-post-input__image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $platinum;
  padding: 20px;
}

.feed-post-input__image {
  border-radius: 4px;
  max-width: 100%;
}

.feed-post-input__input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.feed-post-input__input-toolbar {
  display: flex;
  flex-direction: row;
}
