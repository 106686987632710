@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

$circle-width: 14px;
.average-skill-rating {
  display: inline-block;
  height: $circle-width;

  .average-skill-rating__label-text {
    display: block;
  }

  .average-skill-rating__score-wrapper {
    height: 100%;
    position: relative;
    display: inline-block;
    width: 24px;
  }
  .average-skill-rating__background {
    position: absolute;
    font-size: $circle-width;
  }
  .average-skill-rating__score {
    display: inline-block;
    position: absolute;
    overflow: hidden;
    z-index: 1;
    font-size: $circle-width;
  }
}
