.quiz-question__sentence {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.quiz-question__answer-option {
  border-radius: 4px;
  margin-bottom: 1rem;
  width: 100%;
  text-align: left;
  color: $charcoal;
  padding: 1rem 1.5rem;
  background-color: $white;
  border: 1px solid $platinum;
  &:hover,
  &:focus {
    background-color: $cultured;
  }
}
.quiz-question__image {
  max-width: 100%;
  height: auto;
  margin: 2rem 0;
}
