@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

label {
  width: 100%;
  padding: 10px;
  border: 2px dashed $lavendar-gray;
  text-align: center;
  font-weight: normal;
  background-color: $ghost-white;
  transition: all 0.3s ease;

  &:hover {
    background-color: $bubbles;
  }

  &.file-over {
    background-color: $blizzard-blue;
  }

  &.error {
    border-color: $state-danger-text;
  }
}

.error-text {
  color: $state-danger-text;
}

input {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  height: 0;
}

.file-dropzone__error__icon {
  top: auto;
  height: auto;
}
