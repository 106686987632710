@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.select-input {
  position: relative;

  .select-input__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    z-index: 2;
    pointer-events: none;
    height: auto;
  }
}
.select-input__select {
  padding: 10px 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 4px;
  border-color: $selectize-border-color;
  cursor: pointer;
  color: #333333;
  font-family: inherit;
  font-size: inherit;
  line-height: 20px;
  -webkit-appearance: none;
  z-index: 1;

  &:focus {
    outline-color: var(--button-color);
  }
}
