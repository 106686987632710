@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.dropdown--pips {
  z-index: 10;
}

.dropdown-toggle {
  width: auto;
  height: auto;
  float: right;
  margin-bottom: -10px;
  margin-top: -4px;
  line-height: 20px;
  text-align: right;
  font-size: 26px;
  color: $menu-pip-grey;
  text-shadow: none;

  &:hover {
    text-decoration: none;
    color: $mid-gray;
  }

  &--muted {
    color: $mid-gray;
    font-size: 20px;
  }
}

.dropdown-toggle--float-none {
  float: none;

  .ic {
    vertical-align: middle;
  }
}

.menu-list {
  min-width: 94px;
  margin-top: 10px;
  max-height: 280px;
}

.menu-list--full-width {
  right: -15px;
  max-width: 245px;
  background: $white;
  overflow: auto;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-menu--has-scroll {
  box-shadow: inset 0 -20px 10px -7px $dark-gray;
  transition: box-shadow 300ms ease;
}

.dropdown-menu--has-scroll-end {
  box-shadow: none;
}
