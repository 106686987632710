@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.modal-open .modal--centred {
  display: flex !important;
  height: 100%;
  align-items: center;
}

.modal {
  z-index: 9000;
}

.modal-title {
  display: inline-block;
}

.modal--quickadd {
  z-index: 100001;
}

.modal--delete {
  z-index: 100002;
}

.modal-body {
  img {
    height: auto;
    max-width: 100%;
  }

  h5 {
    font-size: 18px;
  }
}

.modal-body--underlined {
  border-bottom: 1px solid $platinum;
}

.modal-body--section:last-child {
  border-bottom: none !important;
  padding-bottom: 30px !important;
}

.modal-header {
  background-color: $white;
  border-radius: 4px 4px 0 0;
  border-top: 4px solid $persian-green;
  color: $teal-green;
  padding: 20px 30px;

  h4 {
    font-size: 22px;
    width: 96%;
  }

  .avatar {
    border-radius: 25px;
    width: 40px;
  }

  .close {
    font-size: 16px;
    margin-top: 2px;
    color: $persian-green;
    opacity: 1;

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &:hover {
      color: darken($persian-green, 10);
    }
  }
}

.modal-body {
  padding: 20px 30px;

  &.with-top-border {
    border-top: 4px solid $persian-green;
  }
}

.modal-footer {
  border-top: inherit; // Use default bootstrap border
  padding: 20px 30px;
}

.modal-footer--no-border {
  text-align: right;
  border-top: 0; // Remove default bootstrap border
  padding: 0 30px 20px;
}

.modal-header--danger {
  background-color: $brand-danger;
  border-radius: 4px 4px 0 0;
  border-top: 0;
  color: $white;
}

.modal--fullscreen {
  bottom: 15px;
  left: 15px;
  right: 15px;
  top: 15px;

  .modal-dialog {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .modal-content {
    height: auto;
  }
}

.modal-dialog--narrow {
  max-width: 500px;

  .modal-body {
    padding: 20px;
  }

  .modal-header {
    padding: 15px 20px;
  }

  .modal-footer {
    padding: 0 20px 20px;
  }
}
