@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.switch-toggle {
  --active-background-colour: #{$north-texas-green};
  --active-color: #{$white};
  --inactive-background-colour: #{$white};
  --inactive-color: #{$charcoal};
  --disabled-color: #{$cool-grey};
  --border-color: #{$pastel-blue};
  --tab-radius: 5px;

  .switch-toggle__tab__icon {
    top: auto;
    height: auto;
  }
}
.switch-toggle__inner {
  display: inline-flex;
  padding: 2px;
  border-radius: var(--tab-radius);
  border: 1px solid var(--border-color);
  background-color: var(--inactive-background-colour);
}
.switch-toggle__tabs {
  display: inline-flex;
  position: relative;
}
.switch-toggle__tab-background {
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: var(--tab-radius);
  z-index: 0;
  pointer-events: none;
  transition: width ease 0.3s, left ease 0.3s;
}
.switch-toggle__tab {
  color: var(--inactive-color);
  transition: color ease 0.2s;
}
.switch-toggle__tab__button {
  -webkit-appearance: none;
  border-width: 0;
  background-color: initial;
  color: inherit;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  font-size: 14px;
  padding: 5px 10px;
  font-weight: 500;

  &:focus-visible {
    outline: 2px solid var(--button-color);
    outline-offset: -2px;
  }
}

// MODIFIERS
// =====================
.switch-toggle__tab--active {
  color: var(--active-color);
}

.switch-toggle--no-animate {
  .switch-toggle__tab {
    transition: none;
  }
  .switch-toggle__tab--active {
    .switch-toggle__tab__button {
      border-radius: var(--tab-radius);
      background-color: var(--active-background-colour);
    }
  }
}

.switch-toggle--theme-brand {
  --active-background-colour: var(--button-color);
}
