@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.tab-group__tabs {
  position: relative;
  display: inline-flex;
  align-content: center;
  margin-bottom: 20px;
}

.tab-group__animated-active-border {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--button-color);
  z-index: 0;
  pointer-events: none;
  transition: width ease 0.3s, left ease 0.3s;
}

.tab-group__tab {
  flex-grow: 1;
  flex-basis: 0;

  @media screen and (min-width: $screen-sm-min) {
    flex-grow: initial;
    flex-basis: auto;
  }
}

// MODIFIERS
// ==============================

.tab-group--grow {
  .tab-group__tabs {
    display: flex;
  }

  .tab-group__tab {
    flex-grow: 1;
  }
}
