@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

@import 'styles.scss';

.tile-header {
  background-size: cover;
  height: 153px;
  position: relative;
  text-align: center;
}

.tile-header__new_label {
  position: absolute;
  top: 14px;
  left: 15px;
  padding: 5px 15px;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: $white;
  background-color: $brand-primary;
  z-index: 5;
}

.tile-header__completion {
  position: absolute;
  width: 26px;
  height: 26px;
  right: 15px;
  top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $cadmium-green;
  border-radius: 50px;
  background-color: $green;
  color: $white;
  font-weight: bold;
  z-index: 5;

  .ic {
    height: auto;
    top: 0;
  }
}

.tile-header__link {
  position: absolute;
  left: 0;
  top: 0;
  height: $header-height + $body-height - $footer-height; /* footer is positioned absolutely */
  width: 100%;
  background-image: none; // was empty.gif
  z-index: 6;

  &--locked {
    background: rgba(25, 25, 25, 0.3) var(--dark-lock-bg) no-repeat center;
    cursor: not-allowed;
    display: block;
    height: $header-height + $body-height;

    p {
      position: absolute;
      color: $white;
      font-size: 18px;
      margin: 36px 40px;
      visibility: hidden;
    }

    &:hover {
      background: rgba(25, 25, 25, 0.8) var(--light-lock-bg) no-repeat center 120px;
    }

    &:hover p {
      visibility: visible;
    }

    &.tiled {
      position: relative;
      top: -$header-height;
    }
  }
}

.tile-header__joined,
.tile-header__applied,
.tile-header__private {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  align-items: center;
  position: absolute;
  top: 14px;
  right: 15px;
  padding: 5px 15px 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  z-index: 5;
  background-color: $white;
  border: 1px solid $platinum;

  .ic {
    font-size: 15px;
    margin-top: 3px;
    margin-right: 8px;
  }
}

.tile-header--tiled {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100%;

  img {
    height: 50%;
    width: 50%;
    object-fit: cover;
  }
}

.tile-header--overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $brand-primary;
  opacity: 0.6;
  z-index: 4;
  pointer-events: none;
}
