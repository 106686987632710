@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.linked-accounts-switcher-modal {
  color: inherit;

  .linked-accounts-switcher-modal__account-row {
    display: flex;
    gap: 3rem;
    align-items: center;
    padding: 2rem 3rem;
    flex-direction: row;
    border-bottom: 1px solid $platinum;

    .linked-accounts-switcher-modal__account-logo {
      img {
        max-width: 60px;
        max-height: 60px;
        border-radius: 50%;
        box-shadow: 0 0 8px $platinum;
      }
    }

    .linked-accounts-switcher-modal__account-details {
      flex-grow: 1;
    }
  }
}
