@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

// TL;DR:
// Background & border styles should be set on ".base-button__inner:before".
// Text/icon styles should be set on ".base-button__inner".
//
// We use the filter property to modify colors on hover/active because we
// cannot access these color values in scss files, they are stored in the DB.
// To prevent the filter affecting the text readability, the background/border
// is applied to the pseudo "before" element.

$hover-brightness: 85%;
$active-brightness: 70%;
.base-button {
  // CSS Variables
  --base-button-background-color: --button-color;
  --base-button-text-color: --button-text-color;
  // Browser default overrides
  background-image: none;
  background-color: initial;
  border: 0;
  padding: 0;
  box-shadow: none;
  //
  display: inline-block;
  font-size: 16px;
  line-height: normal;
  cursor: pointer;
  user-select: none;

  &:hover .base-button__inner:before {
    filter: brightness($hover-brightness);
  }

  &:active .base-button__inner:before {
    filter: brightness($active-brightness);
  }

  // Selector required to be nested to override Bootstrap icon styles.
  .base-button__icon:not(svg) {
    top: auto;
    height: auto;
  }

  .base-button__icon {
    margin-right: 8px;
  }
}

.base-button__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid var(--base-button-background-color);
    border-radius: 5px;
    z-index: -1;
  }
}

// MODIFIERS
// ==========================================

// Themes
.base-button--theme-primary {
  --base-button-background-color: #{$white};
  --base-button-text-color: #{$dark-persian-green};
  border: 1px solid #{$dark-persian-green};
}
.base-button--theme-brand {
  --base-button-background-color: var(--button-color);
  --base-button-text-color: var(--button-text-color);
}
.base-button--theme-learnamp {
  --base-button-background-color: #{$dark-persian-green};
  --base-button-text-color: #{$white};
}
.base-button--theme-info {
  --base-button-background-color: #{$feldgrau};
  --base-button-text-color: #{$white};
}
.base-button--theme-success {
  --base-button-background-color: #{$cadmium-green};
  --base-button-text-color: #{$white};
}
.base-button--theme-error {
  --base-button-background-color: #{$boston-university-red};
  --base-button-text-color: #{$white};
}
.base-button--theme-dashboard {
  --base-button-background-color: var(--dashboard-color);
  --base-button-text-color: #{$white};
}
.base-button--theme-link {
  --base-button-background-color: #{$medium-teal-blue};
  --base-button-text-color: #{$white};
}

// Rounded
.base-button--rounded {
  border-radius: 999px;

  .base-button__inner:before {
    border-radius: 999px;
  }
}

// Sizes
.base-button--size-small .base-button__inner {
  font-size: 14px;
  padding: 6px 12px;

  @media screen and (min-width: $screen-sm-min) {
    padding: 6px 16px;
  }
}
.base-button--size-medium .base-button__inner {
  padding: 8px 14px;

  @media screen and (min-width: $screen-sm-min) {
    padding: 8px 18px;
  }
}
.base-button--size-large .base-button__inner {
  padding: 12px 16px;

  @media screen and (min-width: $screen-sm-min) {
    padding: 12px 26px;
  }
}
.base-button--size-xl .base-button__inner {
  padding: 18px 18px;

  @media screen and (min-width: $screen-sm-min) {
    padding: 18px 30px;
  }
}

// Variants
.base-button--variant-default {
  .base-button__inner {
    color: var(--base-button-text-color);

    &:before {
      background-color: var(--base-button-background-color);
    }
  }
}
.base-button--variant-outlined {
  .base-button__inner {
    color: var(--base-button-background-color);
  }

  &:hover .base-button__inner {
    filter: brightness($hover-brightness);
  }

  &:active .base-button__inner {
    filter: brightness($active-brightness);
  }
}
.base-button--variant-text {
  .base-button__inner {
    color: var(--base-button-background-color);

    &:before {
      border-color: transparent;
    }
  }

  &:not([disabled]):hover .base-button__inner:before {
    background-color: var(--base-button-background-color);
    filter: opacity(10%);
  }

  &:not([disabled]):active .base-button__inner:before {
    background-color: var(--base-button-background-color);
    filter: opacity(15%);
  }
}
.base-button--variant-plain {
  .base-button__inner {
    color: var(--base-button-background-color);
    padding: 0;

    &:before {
      border-color: transparent;
    }
  }

  &:hover .base-button__inner {
    filter: brightness($hover-brightness);
  }

  &:active .base-button__inner {
    filter: brightness($active-brightness);
  }
}
.base-button--variant-icon {
  .base-button__inner {
    color: var(--base-button-background-color);
    padding: 0;
    border-radius: 999px;
    width: 26px;
    height: 26px;

    &:before {
      border-color: transparent;
    }
  }

  .base-button__icon {
    margin-right: 0;
    margin-left: 0;
  }

  &:not([disabled]):hover .base-button__inner:before {
    background-color: var(--base-button-background-color);
    filter: opacity(10%);
  }

  &:not([disabled]):active .base-button__inner:before {
    background-color: var(--base-button-background-color);
    filter: opacity(15%);
  }

  &.base-button--size-large .base-button__inner {
    width: 34px;
    height: 34px;
  }
}

.base-button--variant-button-icon {
  .base-button__inner {
    padding: 8px;
    font-size: 18px;
    color: var(--base-button-text-color);

    &:before {
      background-color: var(--base-button-background-color);
    }
  }

  .base-button__icon {
    margin: 0;
  }
}

// Disabled
.base-button--disabled[disabled] {
  --base-button-background-color: #{$white-smoke};
  --base-button-text-color: #{$cool-grey};
  cursor: not-allowed;

  &:hover,
  &:active {
    .base-button__inner,
    .base-button__inner:before {
      filter: none;
    }
  }

  // The following variants swap the background and text colors.
  &.base-button--variant-outlined,
  &.base-button--variant-icon,
  &.base-button--variant-text {
    --base-button-background-color: #{$cool-grey};
    --base-button-text-color: #{$white-smoke};
  }
}
