@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

$modal-vertical-padding: 2rem;
$modal-horizontal-padding: 2rem;
$modal-border-color: $platinum;
$modal-z-index: 1050;
$modal-top: 5vh;
$modal-top-sm: 6vh;

.base-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: $modal-z-index;
}

.base-modal__overlay--visible {
  background-color: rgba($black, 0.5);
}

.base-modal {
  position: fixed;
  z-index: $modal-z-index;
  top: $modal-top;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-width: $screen-sm-min) {
    top: $modal-top-sm;
  }
}

.base-modal__inner {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-top: 4px solid $persian-green;
  border-radius: 4px;
  width: 90vw;
  max-height: 80vh;
  max-width: 50rem;

  @media screen and (min-width: $screen-sm-min) {
    width: 50rem;
  }
}

.base-modal--size-large {
  .base-modal__inner {
    max-width: 85rem;
    width: 90vw;
    max-height: calc(100vh - #{$modal-top-sm} * 2);

    @media screen and (min-width: $screen-sm-min) {
      width: 70vw;
    }
  }
}

.base-modal--size-medium {
  .base-modal__inner {
    max-width: 70rem;
    width: 60vw;
    max-height: calc(100vh - #{$modal-top-sm} * 2);

    @media screen and (min-width: $screen-sm-min) {
      width: 70vw;
    }
  }
}

.base-modal--expanded {
  top: 0;
  .base-modal__inner {
    max-width: none;
    max-height: none;
    width: calc(100vw - 1rem);
    height: calc(100vh - 1rem);
  }
}

.base-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: $modal-vertical-padding $modal-horizontal-padding;
  border-bottom: 1px solid $modal-border-color;
}

.base-modal__header-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: $teal-green;

  @media screen and (min-width: $screen-sm-min) {
    font-size: 1.8rem;
  }
}

.base-modal__header-controls {
  flex-shrink: 0;
}

.base-modal__body {
  overflow: auto;
}

.base-modal__body--padded {
  padding: $modal-vertical-padding $modal-horizontal-padding;
}

.base-modal__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $modal-border-color;
  padding: $modal-vertical-padding $modal-horizontal-padding;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-down-enter-active {
  transition: margin-top 0.4s ease, opacity 0.2s ease;
}

.fade-down-leave-active {
  transition: margin-top 0.4s ease, opacity 0.2s ease 0.1s;
}

.fade-down-enter-from,
.fade-down-leave-to {
  margin-top: -40px;
  opacity: 0;
}

.animate-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
