@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.base-pill {
  display: inline-flex;
  align-items: center;
  background-color: $light-gray;
  border-radius: 200px;
  padding: 6px 14px;
  text-align: center;
  font-weight: normal;
  font-size: 1rem;

  @media screen and (min-width: $screen-sm-min) {
    font-size: 1.4rem;
    font-weight: bold;
    min-width: 80px;
  }
}

.base-pill--success {
  background-color: $pale-green;
  color: $deep-jungle-green;
}

.base-pill--warning {
  background-color: $lemon-chiffon;
  color: $chocolate;
}

.base-pill--danger {
  background-color: $bubble-gum;
  color: $rose-wood;
}

.base-pill--learnamp {
  background-color: $blue-chalk;
  color: $indigo;
}

.base-pill--small {
  padding: 4px 12px;

  @media screen and (min-width: $screen-sm-min) {
    font-size: 1.2rem;
    font-weight: normal;
    min-width: initial;
  }
}

.base-pill__icon {
  margin-right: 0.5rem;
}
