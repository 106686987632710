@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.swap-down-enter-active,
.swap-down-leave-active,
.swap-up-enter-active,
.swap-up-leave-active {
  transition: all 0.3s ease;
}

.swap-up-enter,
.swap-up-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.swap-down-enter,
.swap-down-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.swap-down-leave,
.swap-down-enter-to,
.swap-up-leave,
.swap-up-enter-to {
  transform: translateY(0);
  opacity: 1;
}
