@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.button-radio {
  padding: 12px 26px;
  background-color: $white;
  color: #333;
  border: 1px solid #ccc;
  font-weight: 400;
  font-size: 14px;
  font-family: inherit;
  cursor: pointer;
  text-align: center;

  &:focus-within {
    outline: 1px solid -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  &:hover {
    background-color: #e6e6e6;
  }
}

.button-radio--active {
  background-color: #e6e6e6;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);

  &:hover {
    background-color: #d4d4d4;
  }
}

.button-radio__input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.button-radio--small {
  padding-top: 9px;
  padding-bottom: 9px;
}
