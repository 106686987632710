@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.feed-post-comment {
  display: flex;
  align-items: flex-start;
}
.feed-post-comment__avatar {
  margin-right: 15px;
}
.feed-post-comment__menu {
  margin-left: 10px;
  // TODO - hack fix for weird global dropdown CSS
  & ::v-deep .dropdown-menu {
    top: 10px;
  }
}
.feed-post-comment__edit-container {
  width: 100%;
  margin-right: 20px;
}
.feed-post-comment__edit-container__buttons {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.feed-post-comment__bubble {
  flex-grow: 1;
}
.feed-post-comment__input {
  width: 100%;
}

.feed-post-comment__comment-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.feed-post-comment__toolbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.feed-post-comment__like-button {
  cursor: pointer;
}

.feed-post-comment__comment-button {
  padding: 0;
}
