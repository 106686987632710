@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

@import 'styles.scss';

.tile__title-container {
  margin-bottom: 6px;
}

.tile__title {
  word-break: break-word;
  -webkit-line-clamp: 3;
  max-height: 68px;
  &--sub-heading {
    color: $dark-electric-blue;
    font-size: 12px;
    font-weight: normal;
  }
}

.tile__title--md-and-greater {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  font-size: 16px;
  font-weight: bold;
  max-height: 46px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tile__details {
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: 1.65em;
  padding-left: 1px;
  display: -webkit-box;

  .ic {
    margin-right: 5px;
  }
}

.tile__body {
  height: $body-height;
  padding: 16px 8px 5px 8px;
  font-size: $description-font-size;
}

.tile__body--md-and-greater {
  padding: $inner-padding;
  font-size: $description-font-size-desktop;
}

.tile__description {
  margin-top: 10px;
  font-size: $description-font-size;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-height: 4.2em;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tile__description--md-and-greater {
  font-size: $description-font-size-desktop;
}

.avatar {
  width: 22px;
  height: 22px;
  display: inline-block;
  border-radius: 22px;
  margin-right: -7px;
}

.progress {
  margin: 0.5em 0em;
  height: 12px;
  border-radius: 3px;
}
