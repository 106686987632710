@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

legend {
  color: $charcoal;
  font-size: 1.4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.survey-question__question,
legend.survey-question__question {
  color: $charcoal;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 2rem;
  border-bottom: 0;
  padding-bottom: 0;
}
.survey-question__description {
  color: $slate-grey;
  margin-bottom: 2rem;
  font-style: italic;
}

.survey-question__accessibility-label {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.survey-question :deep(.survey-question__answer),
.survey-question__answer {
  width: 100%;
}
