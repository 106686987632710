@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.search-input__input {
  line-height: normal;
}
.search-input__icon--tile-dropdown {
  top: 1px;
}
li {
  &:hover,
  &:focus,
  &:active {
    background-color: $cultured;
  }
  a {
    display: block;
    line-height: normal;
    color: $dark-primary;
  }
}
.search-input--clicked {
  background-color: $ghost-white;
  &:hover,
  &:focus,
  &:active {
    background-color: $ghost-white;
  }
  .ic {
    margin-right: -15px;
    float: right;
    color: $persian-green;
  }
}
