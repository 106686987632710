@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

$border-bottom-width: 2px;

.tab-group-button.tab-group-button {
  box-shadow: inset 0 -#{$border-bottom-width} $pastel-blue;

  & ::v-deep .base-button__inner {
    color: $charcoal;
    height: 100%;

    @media screen and (max-width: $screen-xs-max) {
      padding: 12px;
    }

    &:before {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

// MODIFIERS
// ==============================

.tab-group-button.tab-group-button--no-animate {
  &.tab-group-button--active {
    box-shadow: inset 0 -#{$border-bottom-width} var(--button-color);

    & ::v-deep .base-button__inner {
      color: var(--button-color);
    }
  }
}

.tab-group-button--active ::v-deep .base-button__inner {
  color: var(--button-color);
}
