@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

// Some selectors have been doubled up to ensure they
// override Bootstrap styles that are unintentionally being
// loaded after component styles in storybook.

.btn:focus {
  outline: none;
}
.btn-link,
.btn-link:hover,
.btn-link:active,
.btn-link:visited,
.btn-link:focus {
  outline: none;
  font-size: 16px;
  text-decoration: none;
  color: $dark-midnight-blue;
  padding-left: 0;
}
:not(.btn--cancel):not(.btn-link).btn:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(67, 135, 247, 0.6);
}
.btn--disabled.btn--cancel {
  background-color: unset;
}

.btn.btn--fab {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  overflow: hidden;
  height: 30px;
  width: 30px;
  position: absolute;
  background-color: $white;
  color: $charcoal;
  padding: 0;
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.1);
}

.btn.btn--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  background: none;
  border: none;
  padding: 0;
  box-shadow: none;
  border-radius: 999px;
  min-width: 26px;
  min-height: 26px;

  & ::v-deep {
    .ic {
      top: auto;
      height: auto;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.07);
  }
}
