@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.base-note__inner {
  display: flex;
  flex-direction: row;
  padding: 15px 10px;
  font-size: 14px;

  & ::v-deep .ic {
    top: auto;
  }
}

.base-note__avatar-wrapper {
  color: $dark-gray;
  font-size: 12px;
  text-align: center;
}

.base-note__timestamp {
  display: block;
  margin-top: 5px;
}

.base-note__body {
  position: relative;
  flex-grow: 1;
  padding-right: 60px;
  margin-left: 2rem;
  overflow: hidden;
}

.base-note__author {
  display: flex;
  align-items: center;
}

.base-note__crud-button-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;

  @media screen and (pointer: fine) {
    display: none;

    .base-note:hover &,
    .base-note:focus-within & {
      display: flex;
    }
  }
}

.base-note__content ::v-deep > p:first-of-type {
  // Remove default browser margin from wysiwyg content.
  margin-top: 0;
}

.base-note__edit-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.base-note__private-input {
  display: inline-block;
}

.base-note__private-input-hint {
  display: block;
}

.base-note__attachment {
  display: inline-flex;
  align-items: center;
  margin-top: 2rem;
}

// MODIFIERS
// ====================================================

.base-note--compact {
  &:not(.base-note--loading) {
    cursor: pointer;
  }

  .base-note__body {
    padding-right: 40px;
    margin-left: 1rem;
  }

  .base-note__content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    hyphens: auto;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .base-note__attachment {
    pointer-events: none;
  }
}
