@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

@import 'app/javascript/src/components/atoms/Tile/styles.scss';

.tile {
  height: 280px;
  width: $tile-width;
  min-width: $tile-width;
  margin: 20px auto;
  background-color: $tile-bg-color;
  border-radius: 6px;
  box-shadow: 0 0 9px rgba($tile-shadow, 0.34);
  color: $tile-color;
  font-size: 14px;
  position: relative;
  text-decoration: none;
  transition: all 0.2s;
  overflow: hidden;

  &:hover {
    box-shadow: 0 0 10px rgba($new-blue-light, 0.8);
    transform: scale(1.03);
    text-decoration: none;
  }
}

.tile--md-and-greater {
  height: inherit;
  width: $tile-width-desktop;
  min-width: $tile-width-desktop;
}

.tile .new_like-button {
  z-index: 3;
  font-size: 2em;
  margin-top: 3px;
  margin-right: 2px;
  display: inline-block;

  &:focus {
    outline: none;
  }
}

.tile--add {
  height: $header-height + $body-height;
  padding: 14px;
  color: $link-color;
  font-size: 14px;
  text-align: center;

  .tile--add__content {
    width: 100%;
    height: 100%;
    padding: 15px;
    border: 2px dashed $platinum;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $lavendar-gray;
  }

  strong {
    width: 100%; // IE11 fix for long texts
    display: block;
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 500;
    color: $cool-grey;
  }

  .ic {
    margin-bottom: 42px;
    padding: 29px;
    border: 3px solid $lavendar-gray;
    border-radius: 100px;
    color: $lavendar-gray !important;
    font-size: 32px;
  }
}

.tile--add:hover,
.tile--add:focus,
.tile--add:active {
  color: $dark-primary;

  .ic {
    color: $dark-primary;
  }
}

.tile--team {
  min-width: 360px;
  width: 350px;

  .tile__header {
    height: 204px;
  }

  .tile__body {
    height: 136px;
  }

  .tile__link {
    height: 300px;
  }
}
