@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.document-attachment-preview--image {
  background-color: $white-smoke;
  padding: 1rem;
  border-radius: 0.4rem;
}

.document-attachment-preview__image {
  max-width: 100%;
  max-height: 800px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.document-attachment-preview__image-title {
  margin-top: 0.8rem;
  color: $feldgrau;
  font-size: 1.2rem;
}

.document-attachment-preview__pdf-viewer {
  width: 100%;
  border: 0;
  height: 400px;
  max-height: 70vh;

  @media screen and (min-width: $screen-sm-min) {
    height: 500px;
  }

  @media screen and (min-width: $screen-md-min) {
    height: 600px;
  }
}

.document-attachment-preview__actions {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}
