@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.text-input {
  height: 40px;
  line-height: 20px;
  border: 1px $lavendar-gray solid;
  padding: 8px 15px;
  border-radius: 5px;
  max-height: 30vh;
}

.text-input:focus {
  outline: solid 1px var(--button-color);
  box-shadow: 0px 0px 5px 1px var(--button-color);
}

// MODIFIERS
// =====================
.text-input--rounded {
  border-radius: 20px;
}
.text-input--flat {
  border-color: transparent;
}
.text-input--error {
  border-color: $ou-crimson-red;
}
