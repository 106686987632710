@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.avatar {
  display: block;
  background-size: cover;
  height: 32px;
  width: 32px;
  border-radius: 999px;

  &--clickable {
    cursor: pointer;
  }

  .deactivated {
    filter: grayscale(1);
  }
}

.avatar--size-s {
  @extend .avatar;
  height: 28px;
  width: 28px;
}

.avatar--size-l {
  @extend .avatar;
  height: 35px;
  width: 35px;
}

.avatar--size-xl {
  @extend .avatar;
  height: 42px;
  width: 42px;
}

.avatar--size-xxl {
  @extend .avatar;
  height: 46px;
  width: 46px;
}
