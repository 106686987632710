@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

@mixin active-styles {
  color: white;
  background-color: var(--button-color);
}

.filter-button {
  border: none;
  background-color: $platinum;
  color: $dark-electric-blue;
  padding: 10px 40px;
  border-radius: 5px;
}

.filter-button:not(.filter-button--active) {
  &:hover {
    @include active-styles;
    opacity: 0.65;
  }
}

.filter-button--active {
  @include active-styles;
}
