@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.comment-thread--indented {
  margin-left: 40px;
}

.comment-thread__comment {
  margin-bottom: 30px;
  &:last-of-type {
    margin-bottom: 20px;
  }
}

.comment-thread__new-comment {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.comment-thread__new-comment-avatar {
  margin-right: 10px;
}

.comment-thread__new-comment-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 10px;
}

.comment-thread__new-comment-input {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.comment-thread__new-comment-submit {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.comment-move {
  transition: transform ease 0.5s;
}

.comment-enter,
.comment-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.comment-enter {
  transform: translateY(-30px);
}

.comment-enter-active {
  transition: transform ease 0.3s, opacity ease 0.2s;
}

.comment-leave-active {
  transition: transform ease 0.2s, opacity ease 0.2s;
}
