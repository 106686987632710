@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.progress {
  --progress-background-color: #{$green};

  // Override Bootstrap margin
  margin-bottom: 0;
}

.progress-bar {
  background-color: var(--progress-background-color);

  &.error {
    background-color: $brand-danger;
  }
}

$flare-width: 80px;
.progress-bar--brand {
  --progress-background-color: var(--button-color);
}
.progress-bar--indeterminate {
  .progress-bar {
    width: 100%;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      width: $flare-width;
      background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
      position: absolute;
      top: 0;
      bottom: 0;
      animation-name: sweep;
      animation-duration: 1.5s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }

  &.progress-bar--line {
    box-shadow: none;
    height: 4px;
    border-radius: 0;
    .progress-bar {
      box-shadow: none;
    }
  }
}

@keyframes sweep {
  0% {
    left: -#{$flare-width};
  }

  100% {
    left: 100%;
  }
}
