@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.feed-post__card {
  padding: 0;
}

.feed-post__content-wrapper {
  padding: 20px 30px;
}

.feed-post__content {
  max-height: 160px;
  transition: max-height 0.5s ease;
  overflow: hidden;

  &--showing {
    max-height: var(--contentMaxHeight);
  }

  &--hidden {
    mask-image: linear-gradient(to bottom, white 60%, transparent 100%);
  }
}

.feed-post__image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.feed-post__image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.feed-post__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}

.feed-post__section {
  border-top: 1px solid $light-gray;
}

.feed-post__comment-thread {
  border-top: 1px solid $light-gray;
  padding: 30px 70px 10px 70px;

  @media #{$mobile} {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.feed-post__reply-box {
  padding: 0 72px;
}

.feed-post__read-more {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.feed-post__menu-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  padding-top: 20px;
}

.feed-post__menu {
  position: absolute;
  top: 0;
  right: 0;
  height: auto;
  transition: opacity ease 0.3s;
}
