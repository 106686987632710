@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.tile {
  color: white;
  display: flex;
  height: 104px;
  margin: 0;
  min-width: 150px;
  padding: 0.7em;
  width: 200px;
  transition: box-shadow ease 0.3s;

  &:hover {
    transform: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05), 0px 20px 40px rgba(0, 0, 0, 0.15);
    border: 0;
  }

  .ic {
    bottom: 0;
    color: $ghost-white;
    float: right;
    font-size: 250%;
    margin-left: auto;
    margin-top: auto;
    opacity: 0.7;
  }

  .topic-name {
    flex: 50%;
    font-size: 20px;
    margin: 5px;
    max-width: 190px;
    word-break: break-word;
  }
}
