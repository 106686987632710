@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

$text-size: 12px;
$icon-size: 14px;

.tile__details-container {
  position: absolute;
  top: 215px;
  > div {
    margin-top: 5px;
  }
}

.tile__details {
  color: $dark-electric-blue;
  font-size: $text-size;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .ic {
    font-size: $icon-size;
    margin-right: $text-size;
  }

  .tile__session_count {
    padding-left: 3px;
  }

  ::v-deep &--location {
    color: $dark-electric-blue;
    font-size: $text-size;
    .ic {
      font-size: $icon-size;
      margin-right: 8px;
    }
  }

  ::v-deep &--other_sessions {
    color: $dark-electric-blue;
    font-size: $text-size;
    font-style: italic;
    border: 1px solid;
    border-radius: 999em;
    padding: 3px 10px;
    width: fit-content;
  }
}
