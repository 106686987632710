@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

@mixin light-background {
  background-color: white;
  border: 2px $white-smoke solid;
}

@mixin dark-background {
  background-color: #f3f5f7;
  border: 2px white solid;
}

.rt-input {
  min-height: 40px;
  background-color: white;
  border: 1px $lavendar-gray solid;
  width: 100%;
  border-radius: 5px;
  vertical-align: middle;
  line-height: 20px;
  padding: 10px 10px 0 10px;
}

.rt-input:focus {
  border: solid 1px var(--button-color);
  box-shadow: 0px 0px 5px 1px var(--button-color);
}

.rt-input--dark {
  @include dark-background;
  &:not(.empty) {
    @include light-background;
  }
}

.rt-input--dark:focus {
  @include light-background;
  box-shadow: none;
}

.rt-input--rounded {
  border-radius: 20px;
  padding: 10px 20px 0 20px;

  // Align tinymce placeholder
  &.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
    padding-left: 20px;
  }
}

// Align tinymce placeholder
.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  padding-left: 10px;
}

// remove default tinymce outline
* [contentEditable='true']:focus {
  outline: 0px;
}

// Display placeholder when we consider the input empty
* [contentEditable='true'].empty:before {
  color: #222f3eb3;
  display: block;
  position: absolute;
  content: attr(data-mce-placeholder);
  pointer-events: none;
}

* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
