@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.post-link__inner {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  display: flex;
}
.post-link__remove {
  z-index: 10;
}
.post-link__link {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-basis: 100%;
  color: $charcoal;

  &:hover {
    text-decoration: none;
  }
}
.post-link__text {
  min-height: 50px;
  flex-basis: 100%;
  background-color: $platinum;
  padding: 10px 20px;
}
.post-link__title {
  font-weight: 500;
  font-size: 20px;
}
.post-link__supplier {
  color: $slate-grey;
  font-size: 14px;
  margin-top: 10px;
}
.post-link__image-wrap {
  flex-basis: 100%;
  position: relative;
  min-height: 70px;
}
.post-link__image {
  max-width: 100%;
  position: relative;
  z-index: 5;
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  object-position: center;
}
.post-link__fallback-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  z-index: 1;
}
.post-link__loading-skeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

// MODIFIERS
// =====================
.post-link--preview {
  padding: 15px 35px;
  background-color: $platinum;

  .post-link__text {
    background-color: $white;
  }
  .post-link__title {
    font-size: 16px;
  }
  .post-link__supplier {
    font-size: 12px;
  }
}
.post-link--loading {
  .post-link__text {
    background-color: $white;
    font-size: 16px;
    font-weight: 500;
  }
}
