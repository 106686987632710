@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

@import 'variables';
@import '@/components/atoms/SheetSurface/variables.scss';

.expansion-panel {
  box-shadow: $sheet-surface-box-shadow;
  border: $sheet-surface-border;
}
.expansion-panel__header {
  font-size: 1.4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: left;
  background-color: $white;
  box-shadow: none;
  padding: $expansion-panel-padding-vertical $expansion-panel-padding-horizontal;
  width: 100%;
  border: none;

  &:focus {
    outline: 1px solid var(--button-color);
  }

  &:hover {
    background-color: $white-smoke;
  }
}
.expansion-panel__header-slot {
  flex-grow: 1;
  margin-right: 15px;
}
.expansion-panel__header__icon {
  transition: transform 0.2s ease;

  & ::v-deep .ic {
    top: auto;
    height: auto;
  }
}
.expansion-panel__content {
  background-color: $white;
}

// MODIFIERS
// =====================
.expansion-panel--expanded {
  &:not(.expansion-panel--no-rotate) .expansion-panel__header__icon {
    transform: rotate(-180deg);
    transform-origin: center;
  }
}

.expansion-panel--rounded {
  border-radius: 8px;
  .expansion-panel__header,
  .expansion-panel__content {
    border-radius: 8px;
  }
  &.expansion-panel--expanded:not(.expansion-panel--flat) {
    .expansion-panel__header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .expansion-panel__content {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.expansion-panel--outlined {
  box-shadow: none;
}

.expansion-panel--flat {
  box-shadow: none;
  border: 0;
}

.expansion-panel--disabled {
  opacity: 0.6;
  .expansion-panel__header {
    background-color: $white-smoke;
    cursor: not-allowed;
  }
  .expansion-panel__content {
    background-color: $white-smoke;
  }
}

.expansion-panel--padded {
  .expansion-panel__content__inner {
    padding: $expansion-panel-padding-vertical $expansion-panel-padding-horizontal;
  }
}

.expansion-panel--prominent {
  .expansion-panel__header {
    padding: 2rem;
    font-size: 1.8rem;
  }
}
