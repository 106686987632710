$data-filter-button-max-width: 400px;
$button-border-radius: 4px;
$dropdown-zindex: 151;

%data-filter-button {
  color: inherit;
  border-radius: $button-border-radius;
  background: $white-smoke;
  border-color: transparent;
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: baseline;
  height: auto;
  gap: 0.5rem;
  max-width: $data-filter-button-max-width;

  span {
    white-space: nowrap;
  }

  .filter-value {
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:focus-within {
    box-shadow: 0px 0px 5px 1px var(--button-color);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
    box-shadow: none;
  }
  &.data-filter-button--variant-text {
    text-decoration: underline;
    background-color: transparent;
  }
}

%data-filter-dropdown-arrow {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  transition: transform 0.2s ease;

  &:after {
    content: '';
    position: relative;
    right: 0;
    top: 50%;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: $charcoal transparent transparent;
  }
}

%data-filter-dropdown-menu {
  margin-left: 15px;
  border-radius: 4px;
  border: 1px solid $pastel-blue;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: $data-filter-button-max-width;
  background: $white;
  position: absolute;
  z-index: $dropdown-zindex;
  width: min-content;
  min-width: 180px;

  @media (min-width: $screen-md-min) {
    width: max-content;
  }
}
