$tile-width: 170px;
$tile-width-desktop: 260px;
$description-font-size: 12px;
$description-font-size-desktop: 14px;
$tile-border: $border-default;
$tile-shadow: $shadow-default;
$tile-color: #4a5764;
$tile-bg-color: #ffffff;
$inner-padding: 16px 19px 5px 19px;
$header-height: 153px;
$body-height: 190px;
$footer-height: 60px; /* footer is positioned absolutely */

.tile__properties {
  display: flex;
  justify-content: left;

  .tile__property {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }

  .tile__property + .tile__property:before {
    content: '•';
    color: #d8d8d8;
    padding: 0 5px;
  }
}

.tile__property--overdue {
  color: $boston-university-red;
}

.tile__property--hidden {
  color: $boston-university-red;
}

.tile__properties {
  display: flex;
  justify-content: left;

  .tile__property {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }

  .tile__property + .tile__property:before {
    content: '•';
    color: #d8d8d8;
    padding: 0 5px;
  }
}
