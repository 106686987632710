@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

$border-radius-rounded: 6px;

.button-radio-input__buttons {
  display: flex;
}
.button-radio-input__button {
  margin-left: -1px;
  flex-grow: 1;
}
.button-radio-input__hint {
  display: block;
  margin-top: 5px;
}

.button-radio-input--rounded {
  & ::v-deep .button-radio:first-of-type {
    border-top-left-radius: $border-radius-rounded;
    border-bottom-left-radius: $border-radius-rounded;
  }
  & ::v-deep .button-radio:last-of-type {
    border-top-right-radius: $border-radius-rounded;
    border-bottom-right-radius: $border-radius-rounded;
  }
}
