@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.loader {
  animation: sk-rotateplane 1.2s infinite linear;
  background-size: cover;
  height: 100px;
  margin: 50px auto;
  width: 100px;
}

.loader--xl {
  height: 100px;
  margin: 25px auto;
  width: 100px;
}

.loader--sm {
  height: 50px;
  margin: 25px auto;
  width: 50px;
}

.loader--xs {
  height: 30px;
  margin: 25px auto;
  width: 30px;
}

.loader--xxs {
  height: 25px;
  margin: 0 auto;
  width: 25px;
}

.loader--icon {
  display: inline-block;
  height: 14px;
  width: 14px;
}

@keyframes sk-rotateplane {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
