@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

@import '@/styles/_animations';

.comment-bubble {
  display: block;
  padding: 12px 18px;
  border-radius: 0 25px 25px 25px;
  font-size: 12px;
  background-color: $white-smoke;
  ::v-deep p {
    margin: 0;
  }

  ::v-deep img {
    max-width: 100%;
  }
}

.comment-bubble--focused {
  animation: glowingBorder ease-in 3s;
}

.comment-bubble__details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px;
}

.comment-bubble__details__name {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: $dark-midnight-blue;

  &:hover {
    text-decoration: none;
    color: $dark-midnight-blue;
  }
}

.comment-bubble__details__timestamp {
  white-space: nowrap;
  font-size: 10px;
  color: $dark-gray;
}

.comment-bubble__text {
  white-space: pre-wrap;
  word-break: break-word;
  font-size: 12px;
}
