@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

@import '@/styles/_data-filter.scss';
$subdropdown-max-height: 500px;
$dropdown-max-width: $data-filter-button-max-width;
.segment-filter {
  @extend %data-filter-button;
  gap: 0;
  position: relative;

  &--active {
    background: $bubbles;
    border: 1px solid $medium-teal-blue;
  }

  &--open {
    .data-filter-button__arrow {
      transform: rotate(180deg);
    }
  }

  .data-filter-button {
    border-style: none;
    background-color: inherit;

    .filter-number {
      padding: 0px 0.5rem;
      background-color: $medium-teal-blue;
      color: $white;
      min-width: 2rem;
      border-radius: 50%;
      display: inline-block;
    }
  }
  .data-filter-button__arrow {
    @extend %data-filter-dropdown-arrow;
  }
  .segment-filter-dropdown__main {
    left: -15px;
    top: calc(100% + 2px);
    padding: 1px 0;
    .data-filter-button__arrow {
      transform: rotate(-90deg);
      align-self: center;
    }
    .segment-filter-dropdown__button-item {
      padding: 8px 20px;
      border-style: none;
      background-color: $white;
      justify-content: space-between;
      display: flex;

      &:hover,
      &:focus {
        background-color: $platinum;
      }
    }
  }

  .segment-filter-dropdown__sub {
    padding: 8px 0;
    left: calc(100% - 15px);
    top: -1px;

    :deep(.search-input__container) {
      margin: 0 8px;
    }

    hr {
      width: calc(100% - 32px);
      margin-top: 8px;
      margin-bottom: 8px;
      border-color: $platinum;
    }
    .segment-filter-dropdown__footer {
      padding-top: 8px;
      border-top: 1px solid $pastel-blue;
      margin-top: 8px;
      position: sticky;
      bottom: 0;
      background-color: $white;
      button {
        height: 34px;
        width: min-content;
        margin-left: 20px;
      }
    }
    .segment-filter-dropdown__header {
      position: sticky;
      top: 0;
      background-color: $white;
    }

    .segment-filter-dropdown__search-no-results {
      margin: 8px 20px;
    }
    .segment-filter-dropdown__sub-item-container {
      display: flex;
      flex-direction: column;
      max-height: $subdropdown-max-height;
      overflow: auto;

      fieldset {
        display: flex;
        flex-direction: column;
      }

      label {
        margin: 8px 20px;
        font-weight: inherit;
        user-select: none;
        display: flex;
        align-items: flex-start;

        input {
          margin-right: 8px;
          vertical-align: top;
        }
      }

      .segment-filter-dropdown__label-item,
      .segment-filter-dropdown__label-item input {
        cursor: pointer;
      }

      .segment-filter-dropdown__label-item {
        i {
          color: $feldgrau;
        }
      }

      .segment-filter-dropdown__header.managed-users {
        legend {
          font-weight: bold;
          color: var(--button-color);
          padding: 10px 19px 0;
        }
      }
    }
  }

  .segment-filter-dropdown__main,
  .segment-filter-dropdown__sub {
    @extend %data-filter-dropdown-menu;
  }
}
