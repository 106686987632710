@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.survey-panel-questions__dropdown-menu {
  position: absolute;
  right: 1rem;
  top: 0;
}
.survey-panel-questions__name {
  font-size: 1.8rem;
  font-weight: 500;
}
.survey-panel-questions__content {
  position: relative;
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
}

.survey-panel-questions__edit-actions {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.survey-panel-questions__save-actions {
  display: flex;
  flex-basis: 100%;
  margin-top: 2rem;
  justify-content: space-between;

  @media screen and (min-width: $screen-sm-min) {
    flex-basis: auto;
    margin-top: 0;
    justify-content: flex-end;
  }
}

.survey-panel-questions__draft-button {
  @media screen and (min-width: $screen-sm-min) {
    margin-left: 4rem;
  }
}

.survey-panel-questions__spacer {
  flex-grow: 1;
}
